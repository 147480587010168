export default {
    // Vue 2, Vue 3, Nuxt 2, Nuxt 3, React, React Native, Pinia, TypeScript, Tailwind CSS, Nest.js, PostgreSQL, Python, Vuex, Firebase, Vuetify
    'vue 2': 'text-green-500',
    'vue 3': 'text-green-400',
    'nuxt 2': 'text-green-500',
    'nuxt 3': 'text-green-400',
    react: 'text-blue-500',
    'react native': 'text-blue-500',
    vuex: 'text-green-600',
    pinia: 'text-green-600',
    typescript: 'text-blue-600',
    'tailwind css': 'text-blue-500',
    'nest.js': 'text-red-400',
    postgresql: 'text-blue-300',
    python: 'text-blue-400',
    firebase: 'text-yellow-500',
    vuetify: 'text-blue-500',
    'gpt-4': 'text-red-600',
    'stable difussion': 'text-blue-600',
    openai: 'text-gray-200',
}
